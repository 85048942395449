/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { CacheProvider } from '@emotion/react';
import { makeMuiCache, makeTssCache } from './src/assets/theme/cache';
import { TssCacheProvider } from 'tss-react';

// this is a hack to fix missing styles on refresh in production
// reference: https://github.com/gatsbyjs/gatsby/issues/17676#issuecomment-535796737
// export function replaceHydrateFunction() {
//     return (element, container, callback) => {
//         ReactDOM.render(element, container, callback)
//     }
// }

export const wrapRootElement = ({ element }) => {
    return (
        <CacheProvider value={makeMuiCache()}>
            <TssCacheProvider value={makeTssCache()}>
                {element}
            </TssCacheProvider>
        </CacheProvider>
    )
};

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
    if (window && !location.hash) {
        return [0, 0]
    }

    return true
}